import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import Title from "../components/title"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import { Helmet } from 'react-helmet'
import SubMenu from "../components/common/sub-menu"
// markup 
const AlliedHealth = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  return (
    <Layout>
      <Helmet
        title='Allied health'
      />
       <SubMenu data={subMenu.data[0]}></SubMenu>
      <Hero background="bg-blue-white" data={hero.data[0]} container={true} noSlider={true}></Hero>
      <Title data={dataTitle.data[0]} background="bg-white" ></Title>
      <PreFooter data={dataPrefooter.data[0]}  background="bg-blue"></PreFooter> 
     
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["partnerships-allied-health"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            sub_menu{
              sub_menu_cta {
                text
                link
              }
              title
              links {
                id
                text
                link
                active
              }
            }
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            prefooter_data {
              content
              title
              links {
                id
                text
                url
              }
            }
          }
         
        }
      }
    }
  }
`
export default AlliedHealth
